/// <reference lib="dom" />
import { Either, Optional } from "@dancrumb/fpish";
import { SpanKind, SpanStatusCode } from "@opentelemetry/api";
import { SutroError } from "sutro-common";
import { AuthData } from "sutro-common/auth-data";
import { AnyObject } from "sutro-common/object-types";
import { WrappedSutroApiErrorResponse } from "sutro-common/sutro-api/types";
import { trace, TraceFunctions } from "sutro-common/tracing/trace";

import config from "~/app.config";

import { Api, FetchOptions, HttpResponse } from "./Api";
import { AuthenticatedSutroApi } from "./AuthenticatedSutroApi";
import { STUDIO_HTTP_ERROR_TYPES, StudioHttpError } from "./StudioHttpError";
// For debugging use, eg: `console.log("headers", headers.toObject())`
// @ts-expect-error TS2339 This is just for debugging reasons
Headers.prototype.toObject = function () {
  const output: AnyObject = {};
  this.forEach((value: string, key: string) => (output[key] = value));
  return output;
};

/**
 * This is used to generate a key for each request, so that we can abort requests
 *
 * It's based on method, url, and body. This is intentional, so that if we
 * have duplicate requests, we abort the one in flight and send the new one
 */
const getRequestKey = (request: Request) =>
  `${request.method}|${request.url}|${JSON.stringify(request.body)}`;

export class SutroApi implements Api {
  private apiRoot: string;
  private static instance: SutroApi;
  private _isAuthenticated = false;
  private pendingRequests = new Map<string, AbortController>();
  private authenticatedInstance: AuthenticatedSutroApi | null = null;

  static getAuthData(): AuthData | null {
    const authData = localStorage.getItem("sutro:authData");
    if (authData === null) {
      return null;
    }
    return JSON.parse(authData);
  }

  static updateAuthData(authData: AuthData) {
    localStorage.setItem("sutro:authData", JSON.stringify(authData));
  }

  static clearAuthData() {
    localStorage.removeItem("sutro:authData");
  }

  /**
   * We only need a single instance of the API, so we use the Singleton pattern
   */
  static getApi(): Api {
    if (!this.instance) {
      this.instance = new SutroApi();
    }
    return this.instance as Api;
  }

  isAuthenticated() {
    return this._isAuthenticated;
  }

  /**
   * A common pattern we have is to return a "successful" response with an `error` or a `data` property.
   *
   * This method takes a successful response with an `error` in it and converts it to an `Error`, so that callers
   * can have a streamlined error path
   */
  static mapErrorDataResult<
    D,
    E extends { message: string },
    R extends { error?: E; data?: D },
  >(this: void, result: Either<E, R>): Either<Error, D> {
    return result
      .proceedRight<D>((r): Either<E, D> => {
        if (r.error) {
          return Either.left<E, D>(r.error);
        }
        return Either.right<E, D>(Optional.of(r.data));
      })
      .proceedLeft<Error>((l) => {
        return Either.left<Error, D>(
          l instanceof Error ? l : new SutroError(l.message)
        );
      });
  }

  private constructor() {
    this.apiRoot = config.apiRoot;
  }

  private getHeaders(options: FetchOptions = {}): Headers {
    const { headers = new Headers(), returnAs = "json" } = options;

    if (!headers.has("Accept")) {
      headers.set("Accept", "application/json");
    }
    if (!headers.has("Content-Type") || returnAs !== "blob") {
      headers.set(
        "Content-Type",
        returnAs === "text" ? "text/plain" : "application/json"
      );
    }

    return headers;
  }

  /**
   * Converts a request into an abortable request.
   *
   * It will also cancel an existing duplicate request
   */
  private startAbortableRequest(request: Request): Request {
    if (this.pendingRequests.has(getRequestKey(request))) {
      this.pendingRequests.get(getRequestKey(request))?.abort();
    }
    const abortController = new AbortController();
    const abortableRequest = new Request(request, {
      signal: abortController.signal,
    });

    this.pendingRequests.set(getRequestKey(request), abortController);

    return abortableRequest;
  }

  /**
   * Cleans up the abortable request
   */
  private endAbortableRequest(request: Request) {
    if (this.pendingRequests.has(getRequestKey(request))) {
      this.pendingRequests.delete(getRequestKey(request));
    }
  }

  /**
   * @see Api.authenticated
   */
  authenticate(): Api {
    if (this.authenticatedInstance === null) {
      this.authenticatedInstance = new AuthenticatedSutroApi(this);
    }
    return this.authenticatedInstance as Api;
  }

  /**
   * We pass in the TraceFunctions here, rather than create a new trace, because
   * we don't need another span, we just need to trace the fetch call
   */
  async fetch<R>(
    request: Request,
    options: FetchOptions,
    { setSpanStatus, addSpanError }: TraceFunctions
  ): Promise<HttpResponse<string | R | Blob | Response>> {
    try {
      const abortableRequest = this.startAbortableRequest(request);
      const response = await fetch(abortableRequest);
      this.endAbortableRequest(request);

      const { returnAs = "json" } = options;

      if (response.ok) {
        setSpanStatus({ code: SpanStatusCode.OK });
        if (response.status === 204) {
          return Either.right("");
        }
        switch (returnAs) {
          case "response":
            return Either.right(response);
          case "blob":
            return Either.right(await response.blob());
          case "json":
            return Either.right((await response.json()) as R);
          case "text":
            return Either.right(await response.text());
        }
      }

      let errorResponse: WrappedSutroApiErrorResponse;
      try {
        errorResponse = await response.json();
      } catch {
        return Either.left(
          new StudioHttpError(
            response.status ?? STUDIO_HTTP_ERROR_TYPES.UnknownError,
            response.statusText ?? "Unknown error"
          )
        );
      }

      window.dispatchEvent(
        new CustomEvent("apiError", {
          detail: errorResponse.error ?? "Something went wrong",
        })
      );
      setSpanStatus({
        code: SpanStatusCode.ERROR,
        message: response.statusText,
      });

      return Either.left(
        new StudioHttpError(
          response.status,
          errorResponse.error?.message ?? response.statusText,
          { context: { serverError: errorResponse.error } }
        )
      );
    } catch (e) {
      this.endAbortableRequest(request);
      if (e instanceof DOMException && e.name === "AbortError") {
        return Either.left(
          new StudioHttpError(
            STUDIO_HTTP_ERROR_TYPES.AbortedRequest,
            "Request aborted",
            {
              context: {
                request: { url: request.url, method: request.method },
              },
            }
          )
        );
      }
      const exception =
        e instanceof Error
          ? new StudioHttpError(-1, e.message, { cause: e })
          : new StudioHttpError(-1, "Unknown error", { context: { error: e } });
      addSpanError(exception);
      return Either.left(exception);
    }
  }

  async get<R>(
    path: string,
    {
      payload: payloadArgument,
      options,
    }: {
      payload?: URLSearchParams | null;
      options?: FetchOptions;
    } = { payload: null, options: {} }
  ): Promise<HttpResponse<R | string | Blob | Response>> {
    options = options ?? {};

    const headers = this.getHeaders(options);

    const payload = payloadArgument ?? new URLSearchParams();
    return await trace(
      "sutro-studio2",
      "Sutro API GET",
      (traceFunctions) => {
        const url = `${this.apiRoot}${path}${payload.size === 0 ? "" : "?"}${payload.toString()}`;
        const request = new Request(url, {
          method: "GET",
          headers,
          credentials: "include",
        });

        return this.fetch<R | string | Blob>(request, options, traceFunctions);
      },
      {
        kind: SpanKind.CLIENT,
        attributes: { "sutro.request.options": JSON.stringify(options) },
      }
    ).catch((e) => Either.left<StudioHttpError, R | string>(e));
  }

  /**
   * The unsafe methods (PUT, PATCH, POST) are all very similar, so we have a single method to handle them
   */
  private async unsafeMethod<R>(
    method: "PUT" | "PATCH" | "POST",
    path: string,
    payload?: FormData | Blob | AnyObject | null,
    options: FetchOptions = {}
  ) {
    const headers = this.getHeaders(options);

    return await trace(
      "sutro-studio2",
      `Sutro API ${method}`,
      (traceFunctions) => {
        const url = `${this.apiRoot}${path}`;

        const request = new Request(url, {
          method,
          headers,
          credentials: "include",
          body:
            payload === null
              ? undefined
              : payload instanceof FormData ||
                  payload instanceof Blob ||
                  typeof payload === "string"
                ? payload
                : JSON.stringify(payload),
        });
        return this.fetch<string | R | Blob | Response>(
          request,
          options,
          traceFunctions
        );
      },
      {
        kind: SpanKind.CLIENT,
        attributes: { "sutro.request.options": JSON.stringify(options) },
      }
    );
  }

  async post<R>(
    path: string,
    payload?: FormData | Blob | AnyObject | null,
    options: FetchOptions = {}
  ): Promise<HttpResponse<R | string | Blob | Response>> {
    return this.unsafeMethod("POST", path, payload, options);
  }

  async put<R>(
    path: string,
    payload?: FormData | Blob | AnyObject | null,
    options: FetchOptions = {}
  ): Promise<HttpResponse<R | string | Blob | Response>> {
    return this.unsafeMethod("PUT", path, payload, options);
  }

  async patch<R>(
    path: string,
    payload?: FormData | Blob | AnyObject | null,
    options: FetchOptions = {}
  ): Promise<HttpResponse<R | string | Blob | Response>> {
    return this.unsafeMethod("PATCH", path, payload, options);
  }

  async delete<R>(
    path: string,
    options: FetchOptions = {}
  ): Promise<HttpResponse<R>> {
    const headers = this.getHeaders(options);

    return await trace(
      "sutro-studio2",
      "Sutro API DELETE",
      (traceFunctions) => {
        const url = `${this.apiRoot}${path}`;

        const request = new Request(url, {
          method: "DELETE",
          headers,
          credentials: "include",
        });
        return this.fetch<R>(request, options, traceFunctions) as Promise<
          HttpResponse<R>
        >;
      },
      {
        kind: SpanKind.CLIENT,
        attributes: { "sutro.request.options": JSON.stringify(options) },
      }
    );
  }
}
